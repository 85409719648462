import React, { useState, useContext } from "react";
import { FaBars, FaPlus } from "react-icons/fa";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Settings from "../contexts/Settings";

const Menu = ({ pages }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { settings } = useContext(Settings);

	const linksVariant = {
		opened: {
			display: "flex",
		},
		closed: {
			display: "none",
		},
	};

	return (
		<div className="fixed top-0 left-0 z-[100] p-5">
			<FaBars
				className={`2xl:text-[90px] xl:text-[85px] lg:text-[80px] md:text-[75px] sm:text-[70px] text-[70px] hover:scale-[120%] ease-in-out duration-100 drop-shadow-lg sticky`}
				onClick={() => setIsOpen((prev) => !prev)}
				style={{
					color: settings?.menu_background
						? settings.menu_background
						: "#e8e8e8",
				}}
			/>
			<motion.div
				variants={linksVariant}
				animate={isOpen ? "opened" : "closed"}
				className="absolute top-0 left-0 min-w-[100vw]"
				transition={{ type: "tween", duration: 0.2 }}
			>
				<motion.div
					className={`absolute top-0 left-0 p-5 flex justify-between min-h-[100vh] min-w-[100vw]`}
					style={{
						backgroundColor: settings?.menu_background
							? settings.menu_background
							: "#e8e8e8",
					}}
				>
					<FaPlus
						className={`fixed m-5 top-0 left-0 z-[100] 2xl:text-[90px] xl:text-[85px] lg:text-[80px] md:text-[75px] sm:text-[70px] text-[70px] hover:scale-[120%] ease-in-out duration-100 drop-shadow-lg rotate-45`}
						onClick={() => setIsOpen((prev) => !prev)}
						style={{
							color: settings?.menu_text_color
								? settings.menu_text_color
								: "#000000",
							rotate: "45deg",
						}}
					/>
					<div className="flex lg:flex-row flex-col lg:justify-around justify-end w-full min-h-full lg:items-center">
						{pages.map((page) => (
							<Link
								to={page.path}
								reloadDocument
								key={uuidv4()}
								onClick={() => setIsOpen((prev) => !prev)}
							>
								<div
									className="font-bold uppercase tracking-[-0.15rem] text-[35px]"
									style={{
										fontFamily:
											settings?.text_title_font_name ||
											"serif",
										color:
											settings?.menu_text_color ||
											"#000000",
									}}
								>
									{page.name}
								</div>
							</Link>
						))}
					</div>
				</motion.div>
				<div
					onClick={() => setIsOpen(false)}
					className="min-h-full min-w-full"
				></div>
			</motion.div>
		</div>
	);
};

export default Menu;
