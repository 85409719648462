import React, { useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import API from "../api";
import Settings from "../contexts/Settings";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: {xs: "95%", sm: 600},
	bgcolor: "background.paper",
	border: "0px",
	boxShadow: 24,
	outline: "none",
	p: 4,
};

const ContactUs = () => {
	const [open, setOpen] = React.useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [respType, setRespType] = useState({});
	const { settings } = useContext(Settings);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setRespType({});
	};
	const handleSubmit = async (e) => {
		if (name && email && message) {
			const res = await API.post("contact/", { name, email, message });
			if (res.status >= 200 && res.status <= 300) {
				setRespType({
					type: "green",
					message: "Your message was sent!",
				});
			} else {
				setRespType({
					type: "error",
					message:
						"Something happened, we couldn't deliver your message!",
				});
			}
		} else {
			setRespType({
				type: "error",
				message: "Please fill out all fields",
			});
		}
	};
	return (
		<div>
			<Button variant="outlined" onClick={handleOpen}>
				<Typography
					variant="h5"
					sx={{
						fontFamily:
							settings?.text_body_font_name || "sans-serif",
					}}
				>
					Contact Us
				</Typography>
			</Button>
			<Modal open={open} onClose={handleClose}>
				<Box sx={style}>
					<Typography
						variant="h4"
						align="center"
						sx={{
							fontFamily:
								settings?.text_body_font_name || "sans-serif",
						}}
					>
						{"Get in touch!"}
					</Typography>
					<div className="mt-1 text-center">
						<Typography
							variant="body"
							align="center"
							sx={{
								fontFamily:
									settings?.text_body_font_name ||
									"sans-serif",
							}}
						>{`We'd love to hear from you.
                        Send us a message and we will get back to you as soon as we can`}</Typography>
					</div>

					<Box sx={{ width: "100%", mt: 4 }}>
						<TextField
							fullWidth
							label="Name"
							onChange={(e) => {
								setName(e.target.value);
							}}
						/>
						<TextField
							fullWidth
							className="mt-4"
							label="Your Email"
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
						<TextField
							fullWidth
							multiline
							rows={8}
							className="mt-4"
							label="Message"
							onChange={(e) => {
								setMessage(e.target.value);
							}}
							sx={{
								fontFamily:
									settings?.text_body_font_name ||
									"sans-serif",
							}}
						/>
						<Button
							fullWidth
							onClick={handleSubmit}
							className="mt-5"
							variant="contained"
						>
							Send Message
						</Button>
						<Typography
							className="mt-5"
							align="center"
							color={respType?.type}
						>
							{respType?.message}
						</Typography>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default ContactUs;
