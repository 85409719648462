import React, { useState, useEffect } from 'react'
import Footer from "../components/Footer";
import VideoSlider from '../components/slider/VideoSlider';

const Works = ({ response, category }) => {
  return (
      <>
        <VideoSlider category={category}/>
        <Footer mt={""} response={response}/>
      </>
  )
}

export default Works
