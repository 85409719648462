import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
import Settings from "../contexts/Settings";

const Products = ({ items }) => {
	const { settings } = useContext(Settings);
	return (
		<div
			className={`pt-96 flex flex-col space-y-4 lg:space-y-0 lg:justify-around lg:flex-row relative z-10 text-[${
				settings?.primary_text ? settings.primary_text : "#3d3d3d"
			}]`}
		>
			{items?.map((element, index) => {
				return (
					<motion.div
						key={index}
						initial={{ opacity: 0 }}
						whileInView={{ opacity: 1 }}
						transition={{ duration: 0.5 }}
						whileHover={{ y: -30 }}
						className={`p-10 rounded min-h-full`}
						style={{
							maxWidth: "30rem",
						}}
					>
						{/* <img
							src="https://images.pexels.com/photos/134469/pexels-photo-134469.jpeg?cs=srgb&dl=pexels-alexander-dummer-134469.jpg&fm=jpg"
							alt=""
							className="rounded"
						/> */}
						<div
							className={`flex flex-col justify-center items-center lg:max-w-lg max-w-[15rem] drop-shadow-lg min-w-full text-center`}
						>
							<h3
								className={`font-bold lg:text-4xl min-w-full p-2 rounded-b drop-shadow-lg my-2 text-left`}
								style={{
									color: settings?.primary_text
										? settings.primary_text
										: "#3d3d3d",
									fontFamily:
										settings?.text_title_font_name ||
										"serif",
								}}
							>
								{element.header}
							</h3>
							<div className="min-w-full grid grid-cols-1">
								{element.body.map((el) => (
									<div
										key={uuidv4()}
										className={`font-sans lg:text-xl font-semibold min-w-full p-3`}
										style={{
											color: settings?.secondary_text
												? settings.secondary_text
												: "#d7aa53",
											fontFamily:
												settings?.text_body_font_name ||
												"sans-serif",
										}}
									>
										{el}
									</div>
								))}
							</div>
						</div>
					</motion.div>
				);
			})}
		</div>
	);
};

export default Products;
