import React, { useContext, useEffect, useState } from "react";
import Settings from "../contexts/Settings";
import Marquee from "react-fast-marquee";
import instance from "../api";
import LanguageContext from "../contexts/Language";

const Companies = ({ response }) => {
	const { settings } = useContext(Settings);
	const [testimonials, setTestimonials] = useState([]);
	const { language } = useContext(LanguageContext);

	useEffect(async () => {
		const testimonialsResponse = await instance.get("/testimonials/");
		setTestimonials(testimonialsResponse.data);
	}, []);

	return (
		<>
			{settings?.trusted_companies_enable && (
				<div className="min-w-screen">
					<h3
						className="uppercase text-4xl text-center font-bold pt-96"
						style={{
							color: settings?.primary_text
								? settings.primary_text
								: "#3d3d3d",
							fontFamily:
								settings?.text_title_font_name || "serif",
						}}
					>
						{response[language]?.testimonials_title_text || ""}
					</h3>
					<Marquee gradient={false} className="mt-10">
						{testimonials.map((t, i) => {
							return (
								<div
									key={i}
									className="lg:max-w-[15rem] max-w-[7rem] mr-20 lg:grayscale lg:hover:grayscale-0"
								>
									<img src={t.logo} alt={`${t.name} logo`} />
								</div>
							);
						})}
					</Marquee>
				</div>
			)}
		</>
	);
};

export default Companies;
