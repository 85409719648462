import React, { useRef } from "react";
import { useSwiper, useSwiperSlide } from "swiper/react";
import ClipLoader from "react-spinners/ClipLoader";

const Slide = ({ video, parallaxAmount, parallaxOpacity }) => {
	const swiper = useSwiper();
	const swiperSlide = useSwiperSlide();
	const doRender = () => {
		return swiperSlide.isActive || swiperSlide.isPrev || swiperSlide.isNext;
	};
	doRender();

	return (
		<>
			<div className="hero-slide">
				<div
					className="slide-image"
					data-swiper-parallax={parallaxAmount}
					data-swiper-parallax-opacity={parallaxOpacity}
				>
					<div
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							background: "rgba(255, 255, 255, 0.8)",
							boxShadow: "0 4px 30px rgba(0, 0, 0, 0.8)",
							backdropFilter: "blur(5px)",
							WebkitBackdropFilter: "blur(5px)",
							zIndex: -100,
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							gap: "1rem",
						}}
					>
						<ClipLoader size={"3rem"} color="black" />
						<p>Loading...</p>
					</div>
					<video
						ref={video}
						controlsList={doRender() ? "" : "nodownload"}
						muted
						autoPlay
						loop
						playsInline
						preload="none"
						className="h-full w-full object-cover fixed top-0 left-0 -z-50"
						src={doRender() ? video.video : undefined}
					></video>
				</div>
			</div>
		</>
	);
};

export default Slide;
