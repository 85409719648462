import React, { useState, useEffect, useContext } from "react";
import TextTransition, { presets } from "react-text-transition";
import LanguageContext from "../contexts/Language";
import Settings from "../contexts/Settings";

const Slogan = ({ response }) => {
	const [index, setIndex] = useState(0);
	const { settings } = useContext(Settings);
	const { language } = useContext(LanguageContext);

	useEffect(() => {
		const intervalId = setInterval(
			() => setIndex((index) => index + 1),
			3000 // every 3 seconds
		);
		return () => clearTimeout(intervalId);
	}, []);

	return (
		<h1
			className={`lg:text-6xl text-3xl lg:leading-relaxed leading-loose drop-shadow-lg pt-60 text-center`}
			style={{
				color: settings?.primary_text
					? settings.primary_text
					: "#3d3d3d",
				fontFamily: settings?.text_title_font_name || "serif",
			}}
		>
			<div>
				{response[language]?.slogan_first_line ||
					"Bringing your visions"}
			</div>
			<div>
				{response[language]?.slogan_second_line || "to life is our "}
				<div>
					<TextTransition
						text={
							response[language].home_animation[
								index % response[language].home_animation.length
							]
						}
						springConfig={presets.stiff}
						className={`uppercase font-bold align-bottom italic`}
						inline
						style={{
							color: settings?.secondary_text
								? settings.secondary_text
								: "#d7aa53",
							// backgroundColor: settings?.background
							// 	? settings.background
							// 	: "#e8e8e8",
						}}
					/>
				</div>
			</div>
		</h1>
	);
};

export default Slogan;
