import React, { useContext } from "react";
import "./Privacy.css";
import Settings from "../contexts/Settings";

const Imprint = () => {
	const { settings } = useContext(Settings);
	return (
		<div
			className="min-h-screen p-16 pt-36"
			style={{
				backgroundColor: settings?.background || "#E8E8E8",
			}}
		>
			<div className="container mx-auto">
				<h1 className="font-serif text-6xl mt-64">Impressum</h1>
				<div className="text-lg pt-16 font-sans">
					<p>
						Informationspflicht laut §5 E-Commerce Gesetz, §14
						Unternehmensgesetzbuch, §63 Gewerbeordnung und
						Offenlegungspflicht laut §25 Mediengesetz.
					</p>
					<p>Triangle Studio e.U.</p>
					<p>Reza Majdodin</p>
					<p>Löblichgasse 9/2</p>
					<p>1090 Wien</p>
					<p>Österreich</p>
					<p>Unternehmensgegenstand: Fotografie und Filmproduktion</p>
					<p>GLN: 9110029177797</p>
					<p>GISA: 32680089</p>
					<p>Firmenbuchnummer: FN 534179h</p>
					<p>Firmenbuchgericht: Handelsgericht Wien</p>
					<p>Firmensitz: Löblichgasse 9/2</p>
					<p>Tel.: 0043 6603708880</p>
					<p>E-Mail: office@trianglestudio.at</p>
					<p>
						Mitglied bei: WKO, Landesinnung, Berufsrecht:
						Gewerbeordnung: www.ris.bka.gv.at
					</p>
					<p>
						Aufsichtsbehörde/Gewerbebehörde: Magistratisches
						Bezirksamt des IX. Bezirkes Berufsbezeichnung:
						Berufsfotograf {"&"} Filproduktion Verleihungsstaat:
						Österreich
					</p>
					<p>
						Gemäß Verordnung über Online-Streitbeilegung in
						Verbraucherangelegenheiten (ODR-Verordnung) möchten wir
						Sie über die Online-Streitbeilegungsplattform
						(OS-Plattform) informieren. Verbraucher haben die
						Möglichkeit, Beschwerden an die Online
						Streitbeilegungsplattform der Europäischen Kommission
						unter http://ec.europa.eu/odr?tid=121890417 zu richten.
						Die dafür notwendigen Kontaktdaten finden Sie oberhalb
						in unserem Impressum.
					</p>
					<p>
						Wir möchten Sie jedoch darauf hinweisen, dass wir nicht
						bereit oder verpflichtet sind, an
						Streitbeilegungsverfahren vor einer
						Verbraucherschlichtungsstelle teilzunehmen. Haftung für
						Inhalte dieser Website
					</p>
					<p>
						Unsere Verpflichtungen zur Entfernung von Informationen
						oder zur Sperrung der Nutzung von Informationen nach den
						allgemeinen Gesetzen aufgrund von gerichtlichen oder
						behördlichen Anordnungen bleiben auch im Falle unserer
						Nichtverantwortlichkeit davon unberührt.
					</p>
					<p>
						Wenn Ihnen rechtswidrige Links auf unserer Website
						auffallen, bitte wir Sie uns zu kontaktieren. Sie finden
						die Kontaktdaten im Impressum. Urheberrechtshinweis
					</p>
					<p>
						Alle Inhalte dieser Webseite (Bilder, Fotos, Texte,
						Videos) unterliegen dem Urheberrecht. Bitte fragen Sie
						uns bevor Sie die Inhalte dieser Website verbreiten,
						vervielfältigen oder verwerten wie zum Beispiel auf
						anderen Websites erneut veröffentlichen. Falls
						notwendig, werden wir die unerlaubte Nutzung von Teilen
						der Inhalte unserer Seite rechtlich verfolgen.
					</p>
					<p>
						Sollten Sie auf dieser Webseite Inhalte finden, die das
						Urheberrecht verletzen, bitten wir Sie uns zu
						kontaktieren. Bildernachweis
					</p>
					<p>
						Die Bilder, Fotos und Grafiken auf dieser Webseite sind
						urheberrechtlich geschützt.
					</p>
					<p>
						Die Bilderrechte liegen bei den folgenden Fotografen und
						Unternehmen:
					</p>
					<p>Fotograf Reza Majdodin</p>
					<p>Alle Texte sind urheberrechtlich geschützt.</p>
				</div>
			</div>
		</div>
	);
};

export default Imprint;
