import React from "react";

const BackgroundVideo = ({ video }) => {
	return (
		<span>
			<video
				muted
				autoPlay
				loop
				playsInline
				className="h-full w-full object-cover fixed top-0 left-0 -z-50"
				src={video}
			></video>
		</span>
	);
};

export default BackgroundVideo;
