import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { SettingsProvider } from "./contexts/Settings";

ReactDOM.render(
	<SettingsProvider>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</SettingsProvider>,
	document.getElementById("root")
);
