import React from "react";
import { IoMdArrowRoundDown } from "react-icons/io";
import { motion } from "framer-motion";

const CallToActionArrow = () => {
	return (
		<motion.div
			className="mt-72"
			animate={{ y: -20 }}
			transition={{
				repeat: Infinity,
				type: "tween",
				repeatType: "reverse",
			}}
		>
			<a href="#welcome">
				<IoMdArrowRoundDown className="text-4xl text-white" />
			</a>
		</motion.div>
	);
};

export default CallToActionArrow;
