import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Works from "./pages/Works";
import About from "./pages/About";
import Project from "./pages/Project";
import instance from "./api";
import Menu from "./components/Menu";
import { LanguageProvider } from "./contexts/Language";
import BrandStrip from "./components/BrandStrip";
import fallBack from "./fallBack.json";
import pages from "./pages/pages";
import LanguageSelector from "./components/LanguageSelector";
import "./index.css";
import Settings from "./contexts/Settings";
import Privacy from "./pages/Privacy";
import Imprint from "./pages/Imprint";
import Tours from "./pages/Tours";
import TourDetails from "./pages/TourDetails";

const App = () => {
	const [videoSrc, setVideoSrc] = useState("");
	const [response, setResponse] = useState(fallBack);
	const { settings } = useContext(Settings);

	useEffect(async () => {
		const videoResponse = await instance.get("featured/");
		setVideoSrc(`https://www.trianglestudio.org${videoResponse.data.video}`);

		const textResponse = await instance.get("texts/");
		setResponse(textResponse.data);
	}, []);

	return (
		<LanguageProvider>
			<LanguageSelector />
			<BrandStrip />
			<Router>
				<Menu pages={pages} />
				<Routes>
					<Route
						path="/"
						element={
							<Home response={response} videoSrc={videoSrc} />
						}
						exact
					/>
					<Route
						path="/business/"
						element={<Works response={response} category={"BUSINESS"}/>}
						exact
					></Route>
					<Route
						path="/films/"
						element={<Works response={response} category={"FILM"}/>}
						exact
					></Route>
					<Route path="/tours/" element={<Tours response={response} />} exact></Route>
					<Route path="/tours/:id" element={<TourDetails response={response} />} exact />
					<Route path="/about/" element={<About response={response} />} exact></Route>
					<Route path="/privacy/" element={<Privacy response={response} />} exact></Route>
					<Route path="/project/:id" element={<Project response={response} />} exact />
					<Route path="/imprint/" exact element={<Imprint response={response} />}></Route>
				</Routes>
			</Router>
		</LanguageProvider>
	);
};

export default App;
