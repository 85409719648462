import React, { useContext } from "react";
import Typist from "react-typist";
import { motion } from "framer-motion";
import Settings from "../contexts/Settings";
import LanguageContext from "../contexts/Language";

const WelcomeText = ({ response }) => {
	const { settings } = useContext(Settings);
	const { language } = useContext(LanguageContext);


	return (
		<div className="mb-52">
			<h2
				className="font-bold drop-shadow-lg pb-2 inline"
				style={{
					fontFamily: settings?.text_title_font_name || "serif",
				}}
				>
				{response[language]?.main_heading_text ||
					"Welcome to Triangle Studio"
				}
			</h2>
			<motion.div
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				transition={{ duration: 0.5 }}
			>
				<h2
					className={`lg:text-4xl text-2xl p-2 drop-shadow-lg box-content max-w-fit mx-auto`}
					style={{
						backgroundColor: settings?.background
							? settings.background
							: "#e8e8e8",
						color: settings?.secondary_text
							? settings.secondary_text
							: "#d7aa53",
						fontFamily:
							settings?.text_body_font_name || "sans-serif",
					}}
				>
					{response[language]?.sub_title_text ||
						"Your creative partner for multimedia projects"}
				</h2>
			</motion.div>
		</div>
	);
};

export default WelcomeText;
