import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import API from "../api";


import { useIsMounted } from "../hooks/useIsMounted";

const Tour = ({ response }) => {
    const [tourSrc, setTourSrc] = useState("")
    const params = useParams();
    const isMounted = useIsMounted();
    
    const getTours = async () => {
        API.get(`tours/${params.id}/`).then((resp) => {
          if (isMounted.current) { 
            setTourSrc(resp.data.link)
          }
        }).catch((err) => {
          if (isMounted.current) {  
            setTourSrc("")
           }
        })
    }

    useEffect(() => {
      getTours()
    }, [])

  return (
    <div className="relative">
        <div className="absolute top-10 left-40 z-10 cursor-pointer">
          <Link className="text-4xl text-white" to={"/tours/"}>{"< Back"}</Link>
        </div>
        <div id="Fh0gTDfzROv" className="h-screen">
            <iframe src={tourSrc} width={"100%"} height="100%" className="fixed top-0 right-0 left-0 bottom-0"></iframe>
        </div>
    </div>
  )
}

export default Tour