import axios from "axios";
// const baseURL = "http://localhost:8000/api/"
const baseURL = "https://www.trianglestudio.org/api/"

export default axios.create({
	// baseURL: `https://www.trianglestudio.org/api/`,
	baseURL: baseURL,
	validateStatus: function (status) {
		return status >= 200 && status < 501;
	},
});
