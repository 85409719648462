import React, { useState, useEffect, useContext } from 'react'
import { useParams, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import { ProGallery } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';

import Settings from "../contexts/Settings";
import LanguageContext from "../contexts/Language";
import ContactUs from '../components/ContactUs';
import Footer from "../components/Footer";
import YoutubeEmbed from '../components/youtube/YoutubeEmbed';
import API from "../api";

const Project = ({ response }) => {
  const [details, setDetails] = useState({})
  const [gallery, setGallery] = useState([])
  const { settings } = useContext(Settings);
  const { language } = useContext(LanguageContext);
  const params = useParams();
  const location = useLocation();

  const _ = (key) => {
    key = language === "de" ? `de_${key}` : key;
    return key
};

  const options = {
      galleryLayout: settings?.gallery_mode || 0,
      hoveringBehaviour: 'NEVER_SHOW',
    };

const container = {
    width: window.innerWidth * (10/12),
    height: window.innerHeight
  };


  const determineElement = (element) => {
      if (element.type === 1) {
          return (
              <video
                style={{width:"100%"}}
                playsInline
                controls
                className=""
                src={element.video}>
              </video>
          )
      } else if (element.type === 2) {
          return (
              <img src={element.image} alt=""/>
          )
      } else if (element.type === 3) {
          return (
              <div className="text-center">
                  <YoutubeEmbed embedId={element.source}/>
              </div>

          )
      } else if (element.type === 4) {
          return (
              <div className="lg:pr-[50%] ml-10">
                  <p className="text-xl font-light">{element.text}</p>
              </div>

          )
      }
  }

  useEffect(() => {
      const getDetails = async () => {
          const res = await API.get(`/project/${params.id}`)
          if (res.status === 200) {
              setDetails(res.data)
              const arr = [];
              res.data?.gallery?.map((image) => (
                  arr.push({
                      itemId: image.id.toString(),
                      mediaUrl: image.image,
                      metadata: {
                          type: "image",
                          width: image.width.toString(),
                          height: image.height.toString(),
                      }
                  })
              ))
              setGallery(arr);

          }
      }
      getDetails()
  }, [])

  return (
    <>
    <Box sx={{bgcolor:settings?.background}}>
        <Grid container
            sx={{
                pt: {xs:"50px", sm:"10%", lg:"5%"},
                pb: 10,
            }}
            justifyContent="center"
            alignItems="center">
            <Grid item xs={11} md={details?.description ? 6 : 11}
                py={{
                    xs: "5%",
                    xl: "1%",
                }}
                className="text-center"
            >
                <h1 className="text-6xl font-semibold">
                    {details?.title || location?.state?.title}
                </h1>
            </Grid>
            <Grid item xs={11} md={6}
                py={{
                    xs: "5%",
                    xl: "1%",
                }}
                pr={{
                    xs:"10px",
                    xl:"8%"
                }}>
                <p className="text-center md:text-left text-2xl pr-5 font-light leading-relaxed">
                    {details[_("description")]}
                </p>
            </Grid>
            <Grid item xs={10} md={10}>
                { gallery && <ProGallery
                    items={gallery}
                    options={options}
                    container={container}
                  />
                }
            </Grid>

            {details?.fields?.map((element) => (
                <Grid item xs={10} key={element.id} mt={5}>
                    <div>
                        {determineElement({...element})}
                    </div>
                </Grid>

            ))}
        </Grid>

        <Box className="text-center" sx={{ mb: 0, pb: 5}}>
            <h2 style={{color: settings?.primary_text}}
                className="mb-0 text-2xl font-semibold">
                {"Ready to work with us?"}
            </h2>
            <div>
                <Box sx={{padding:"0 25% 0 25%", marginTop:"2%"}}>
                    <Divider variant="middle">
                      <ContactUs />
                    </Divider>
                </Box>
            </div>
        </Box>
    </Box>
    <Footer response={response} />
    </>
  )
}

export default Project
