import React, { useContext } from "react";
import Products from "../components/Products";
import Slogan from "../components/Slogan";
import Footer from "../components/Footer";
import CallToActionArrow from "../components/CallToActionArrow";
import WelcomeText from "../components/WelcomeText";
import BackgroundVideo from "../components/BackgroundVideo";
import LanguageContext from "../contexts/Language";
import Settings from "../contexts/Settings";
import Companies from "../components/Companies";

const Home = ({ response, videoSrc }) => {
	const { language } = useContext(LanguageContext);
	const { settings } = useContext(Settings);
	return (
		<>
			<BackgroundVideo video={videoSrc} />
			<div className="container mx-auto snap-y snap-mandatory overflow-hidden">
				<div className="min-h-screen flex flex-col justify-center items-center snap-center snap-always">
					<Slogan response={response} />
					<CallToActionArrow />
				</div>
				<div
					className={`lg:text-6xl text-4xl text-center font-roboto-serif pt-96 drop-shadow-lg leading-relaxed snap-center snap-always text-bold`}
					id="welcome"
					style={{
						color: settings?.primary_text
							? settings.primary_text
							: "#3d3d3d",
					}}
				>
					<WelcomeText response={response} />
				</div>
				<div>
					<Companies response={response} />
				</div>
				<Products items={response[language].home_table} />
			</div>
			<Footer mt={"mt-96"} response={response} />
		</>
	);
};

Home.path = "/";

export default Home;
