import React, { useContext } from "react";
import LanguageContext from "../contexts/Language";
import Settings from "../contexts/Settings";
import flagUSA from "../assets/flags/united-states-of-america-flag-png-large.jpg";
import flagGer from "../assets/flags/germany-flag-png-large.jpg";

const LanguageSelector = () => {
	const { language, setLanguage } = useContext(LanguageContext);
	const { settings } = useContext(Settings);
	return (
		<div
			className={`fixed bottom-0 left-0 flex p-2 z-[11]`}
			style={{
				backgroundColor: settings?.background
					? settings.background
					: "#e8e8e8",
			}}
		>
			<div
				onClick={() => setLanguage("en")}
				className="cursor-pointer hover:scale-[110%] ease-in-out duration-100 max-w-[50px] font-mono font-bold text-xl"
			>
				EN
			</div>
			<div className="min-w-[10px]"></div>
			<div
				onClick={() => setLanguage("de")}
				className="cursor-pointer hover:scale-[110%] ease-in-out duration-100 max-w-[50px] font-mono font-bold text-xl"
			>
				DE
			</div>
		</div>
	);
};

export default LanguageSelector;
