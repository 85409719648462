import React, { useContext } from "react";
import {
	ParallaxBanner,
	ParallaxProvider,
	Parallax,
} from "react-scroll-parallax";

import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import TeamPic from "../assets/team_placeholder.jpg";
import LanguageContext from "../contexts/Language";

import Person3 from "../assets/person3.jpg";
import Settings from "../contexts/Settings";

const PersonParallax = ({ person, desktop }) => {
	const { language } = useContext(LanguageContext);
	const { settings } = useContext(Settings);

	return (
		<Grid
			item
			style={{
				minHeight: "20vh",
				width: "70%",
				marginTop: "10%",
				marginLeft: "15%",
			}}
		>
			<Paper
				sx={{
					position: "relative",
					paddingTop: "10px",
					marginLeft: { xs: "1%", sm: "10%", md: "0px", lg: "5%" },
					marginRight: { xs: "1%", sm: "8%", md: "0px", lg: "5%" },
					marginTop: {
						xs: "10%",
						sm: "10%",
						md: "10%",
					},
				}}
				className="text-center"
			>
				<Avatar
					sx={{
						width: { xs: 135, sm: 140, md: 145, lg: 175, xl: 200 },
						height: { xs: 135, sm: 140, md: 145, lg: 175, xl: 200 },
						position: "absolute",
						left: { xs: -75, sm: -80, md: -90, lg: -110, xl: -120 },
						right: 0,
						top: { xs: -5, sm: -10, md: -15, lg: -30, lg: -35 },
						marginRight: "auto",
					}}
					src={person.mobile_picture}
				/>

				<Box
					sx={{
						position: "relative",
						paddingTop: "0px",
					}}
				>
					<Typography
						variant={desktop ? "h4" : "h5"}
						sx={{
							fontFamily:
								settings?.text_body_font_name || "sans-serif",
						}}
					>
						{person.name}
					</Typography>
					<Typography
						variant="body2"
						px={{ xs: 8 }}
						sx={{
							fontFamily:
								settings?.text_body_font_name || "sans-serif",
						}}
					>
						{language === "en"
							? person.job_title
							: person.de_job_title}
					</Typography>

					<Stack direction="row" justifyContent="center" mt={2}>
						<IconButton
							target="_blank"
							href={`//${
								person.linkedin || "https://linkedin.com"
							}`}
						>
							<LinkedInIcon />
						</IconButton>
						<IconButton
							target="_blank"
							href={`//${person.instagram}`}
						>
							<InstagramIcon />
						</IconButton>
						<IconButton
							target="_blank"
							href={`//${person.twitter}`}
						>
							<TwitterIcon />
						</IconButton>
					</Stack>
				</Box>
			</Paper>
		</Grid>
	);
};

export default PersonParallax;
