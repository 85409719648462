import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

import API from "../api";
import { useIsMounted } from "../hooks/useIsMounted";
import LanguageContext from "../contexts/Language";
import Settings from "../contexts/Settings";

const Tours = ({ response }) => {
	const [tours, setTours] = useState([]);
	const [activeTourID, setActiveTourID] = useState(0);
	const [activeTour, setActiveTour] = useState("");
	const [pageSettings, setPageSettings] = useState();
	const { settings } = useContext(Settings);
	const { language } = useContext(LanguageContext);
	const isMounted = useIsMounted();

	const getTours = async () => {
		API.get("pages/tours/").then((resp) => {
			setPageSettings(resp.data);
		});
		API.get("tours/")
			.then((resp) => {
				if (isMounted.current) {
					setTours(resp.data);
					if (resp.data.length !== 0) {
						setActiveTour(resp.data[0].link);
					}
				}
			})
			.catch((err) => {
				if (isMounted.current) {
					setTours([]);
					setActiveTour("");
				}
			});
	};

	const displayMedia = () => {
		if (!pageSettings || !pageSettings.hero_media) {
			return <></>;
		}
		const url = new URL(pageSettings.hero_media);
		const image_extensions = ["jpg", "png", "jpeg"];
		const extension = url.pathname.split(".").pop();
		if (image_extensions.includes(extension)) {
			return <img src={pageSettings.hero_media} alt="" />;
		} else {
			return (
				<video
					muted
					autoPlay
					loop
					playsInline
					className="h-full w-full object-cover"
					src={pageSettings.hero_media}
				></video>
			);
		}
	};

	useEffect(() => {
		getTours();
	}, []);

	const changeTour = (e) => {
		setActiveTourID(e.target.id);
		setActiveTour(tours[e.target.id].link);
	};

	return (
		<div>
			<div className="flex flex-col items-center justify-center list-none bg-[#4f5b4f] min-h-screen">
				<div>{displayMedia()}</div>
				<div className="flex flex-col justify-center my-10 md:my-16 text-center min-h-screen">
					<div className="max-w-5xl mx-auto">
						<h1
							className="text-[#d5e9d6] text-2xl md:text-3xl lg:text-5xl"
							style={{
								fontFamily:
									settings?.text_title_font_name || "serif",
							}}
						>
							{response[language]?.virtual_tours_title ||
								"Virtual Tours"}
						</h1>
						<p
							className="text-[#acc5ae] py-5 px-2 md:px-0 text-lg xl:text-2xl whitespace-pre-line"
							style={{
								fontFamily:
									settings?.text_body_font_name ||
									"sans-serif",
							}}
						>
							{response[language]?.virtual_tours || "Loading"}
						</p>
					</div>
					<div className="">
						{Array.isArray(tours) && (
							<ul
								className={`mx-auto px-4 md:px-0 flex max-w-3xl flex-wrap cursor-pointer text-xl md:text-2xl xl:text-3xl list-none text-center`}
								style={{ maxWidth: "max-content" }}
							>
								{tours?.map((tour, index) => (
									<Link
										to={`./${tour.id}`}
										className={"flex-1"}
									>
										<li
											className={`border text-[#c3ddc3] hover:text-[#defedf] hover:bg-white/30 hover:rounded-md py-3 px-16 md:px-24 ${
												tour.id === activeTourID
													? "text-white"
													: ""
											}`}
											style={{
												color:
													settings?.tours_text_color ||
													"inherit",
											}}
											value={index}
											key={tour.id}
											id={index}
										>
											{tour.name}
										</li>
									</Link>
								))}
							</ul>
						)}
					</div>
				</div>
			</div>
			<Footer mt={""} response={response} />
		</div>
	);
};

export default Tours;
