import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import API from "../../api";

import "swiper/css";
// import Swiper from 'react-id-swiper';
import { Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import { motion } from "framer-motion";
import { useDebouncedCallback } from "use-debounce";

import Settings from "../../contexts/Settings";

import "./VideoSlider.css";
import Slide from "./Slide";

const VideoSliderConfigs = {
	// containerClass: 'swiper-container hero-slider',
	parallax: true,
	autoHeight: true,
	centeredSlides: true,
	grabCursor: true,
	speed: 500,
	spaceBetween: 0,
	effect: "slide",
};

const VideoSlider = ({ category }) => {
	const [videos, setVideos] = useState();
	const [activeVideo, setActiveVideo] = useState(0);
	const [parallaxSwiper, setParallaxSwiper] = useState(null);
	const [swiperInstance, setSwiperInstance] = useState(null);
	const [controlledSwiper, setControlledSwiper] = useState(null);
	const { settings } = useContext(Settings);
	const swiper = useSwiper();
	const parallaxAmount = parallaxSwiper ? parallaxSwiper.width * 0.95 : 0;
	const parallaxOpacity = 0.5;

	const changeSlide = (index) => {
		setActiveVideo(parseInt(index));
		swiperInstance.slideTo(parseInt(index), 1000);
	};

	const debouncedChangeSlide = useDebouncedCallback(changeSlide, 1000);

	useEffect(() => {
		const getVideos = async () => {
			const res = await API.get(`works/?cat=${category}`);
			const data = await res.data;
			setVideos(res.data);
		};
		getVideos();
	}, []);
	return (
		<div>
			<Swiper
				{...VideoSliderConfigs}
				scrollbar={{ draggable: true }}
				pagination={{ clickable: true }}
				onSwiper={(swiper) => setSwiperInstance(swiper)}
				onSlideChange={(swiper) => setActiveVideo(swiper.activeIndex)}
				modules={[Controller]}
				controller={{ control: controlledSwiper }}
			>
				{videos?.map((video) => (
					<SwiperSlide key={video.id}>
						<Slide
							video={video}
							parallaxAmount={parallaxAmount}
							parallaxOpacity={parallaxOpacity}
						/>
					</SwiperSlide>
				))}
			</Swiper>
			<div
				className="video-names
						text-slate-400
						right-6
						md:right-[50px]
						md:text-4xl
						sm:text-2xl text-xl"
			>
				{videos?.map((video, index) => (
					<motion.div
						whileHover={{
							scale: 1.2,
						}}
						className={`md:mt-2 w-full
							  ${index === activeVideo ? "text-white" : ""}
							`}
						key={video.id}
						value={index}
						onClick={(e) => changeSlide(e.target.id)}
						onMouseOver={(e) => debouncedChangeSlide(e.target.id)}
					>
						<Link
							className="inline-block w-full text-right"
							style={{
								color: settings?.works_text_color || "inherit",
							}}
							to={`/project/${video.id}`}
							value={index}
							id={index}
							state={{
								video: video,
								title: video.name,
							}}
						>
							{video.name}
						</Link>
					</motion.div>
				))}
			</div>
		</div>
	);
};

export default VideoSlider;
