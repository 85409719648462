import { createContext, useState, useEffect } from "react";
import instance from "../api";

const Settings = createContext({});

export const SettingsProvider = ({ children }) => {
	const [settings, setSettings] = useState(null);

	useEffect(async () => {
		const settingsResponse = await instance.get("settings/");
		setSettings(settingsResponse.data);
	}, []);

	return (
		<Settings.Provider value={{ settings }}>{children}</Settings.Provider>
	);
};

export default Settings;
