import React, { useContext } from "react";
import Settings from "../contexts/Settings";

const BrandStrip = () => {
	const { settings } = useContext(Settings);
	const isHidden = settings?.side_bar_disabled || true;
	return (
		<>
			{isHidden || (
				<div
					className={`fixed top-0 lg:right-0 -z-1 h-full vertical-brand-text text-bold lg:p-0	align-top drop-shadow-lg lg:text-2xl md:text-sm text-xs flex justify-around tracking-wide lg:max-h-screen max-h-4 min-w-full lg:max-w-fit lg:min-w-0`}
					style={{
						color: settings?.primary_text
							? settings.primary_text
							: "#3d3d3d",
						backgroundColor: settings?.background
							? settings.background
							: "#e8e8e8",
						fontFamily: settings?.text_title_font_name || "serif",
					}}
				>
					<span>TRIANGLE STUDIO</span>
					<span className="lg:rotate-90">▲</span>
					<span>TRIANGLE STUDIO</span>
					<span className="lg:rotate-90">▲</span>
					<span>TRIANGLE STUDIO</span>
					<span className="lg:rotate-90">▲</span>
					<span>TRIANGLE STUDIO</span>
					<span className="lg:rotate-90">▲</span>
					<span>TRIANGLE STUDIO</span>
				</div>
			)}
		</>
	);
};

export default BrandStrip;
