import React, { useState, useEffect, useContext } from "react";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

import PersonParallax from "../components/PersonParallaxBanner";
import PersonDesktop from "../components/PersonDesktop";
import PersonParallaxMobile from "../components/PersonParallaxMobile";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import LanguageContext from "../contexts/Language";
import MobileHeader from "../assets/logo_mobile.png";

import API from "../api";
import Settings from "../contexts/Settings";


const About = ({ response }) => {
	const [people, setPeople] = useState([]);
	const [settings, setSettings] = useState();
	const isDesktop = useMediaQuery("(min-width:1024px)");
	const { settings: settingsFromContext } = useContext(Settings);
	const { language } = useContext(LanguageContext);

	

	useEffect(() => {
		const getPeople = async () => {
			const res = await API.get("people/");
			if (res.status === 200) {
				setPeople(res.data);
			}
		};
		const getSettings = async () => {
			const res = await API.get("settings/about/");
			if (res.status === 200) {
				setSettings(res.data);
			}
		};
		getPeople();
		getSettings();
	}, []);


	const _ = (key) => {
		key = language === "de" ? `de_${key}` : key;
		if (settings && settings[key]) {
		  return settings[key];
		} else {
		  return "";
		}
	};

	return (
		<ParallaxProvider>
			<div className="about-container" style={{backgroundColor: settings?.background_color}}>
				<div className="team-img-container">
					{isDesktop ? (
						<img
							className="team-img overflow-hidden"
							style={{ width: "100%" }}
							src={
								"//www.trianglestudio.org" +
								settings?.header_picture
							}
							alt=""
						/>
					) : (
						<img
							className="team-img overflow-hidden"
							style={{ width: "100%" }}
							src={
								"//www.trianglestudio.org" +
								settings?.header_picture_mobile
							}
							alt=""
						/>
					)}
				</div>
				<h1
					className="text-center mt-7 font-bold text-6xl"
					style={{
						color: settings?.about_us_title_text_color,
						fontFamily:
							settingsFromContext?.text_body_font_name ||
							"sans-serif",
					}}
				>
					{_("title")}
				</h1>
				<div className="flex justify-center text-center mt-10 text-xl md:text-2xl">
					<p
						className="px-4 md:px-0 max-w-screen-sm md:font-medium text-center"
						style={{
							color: settings?.about_us_description_text_color,
							fontFamily:
								settingsFromContext?.text_body_font_name ||
								"sans-serif",
						}}
					>
						{_("description")}
					</p>
				</div>
				<div>
					<div>
						<Box sx={{ padding: "0 25% 0 25%", marginTop: "2%" }}>
							<Divider variant="middle">
								<ContactUs />
							</Divider>
						</Box>
					</div>
					<Grid container
						sx={{ mb: "4%" }}
						spacing={{ xs: 0, xl: 0 }}
						justifyContent="center"
						alignitems="center"
					>
						{people?.map((person, index) => (
							<Grid item container xs={12} md={6} xl={5} key={index}
								justifyContent="center"
								alignitems="center"
								style={{ textAlign: "center" }}
								sx={{
									mt: { xs: 0, sm: 0 },
									pt: 0,
								}}
							>
								<PersonDesktop
									{...{
										key: person.id,
										desktop: isDesktop,
										person,
									}}
								/>
							</Grid>
						))}
					</Grid>
					<Footer response={response} />
				</div>
			</div>
		</ParallaxProvider>
	);
};

export default About;
