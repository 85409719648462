import React, { useContext } from "react";
import Settings from "../contexts/Settings";
import { Link } from "react-router-dom";
import LanguageContext from "../contexts/Language";
import { SocialIcon } from "react-social-icons";

const Footer = ({ mt, response }) => {
	const { settings } = useContext(Settings);
	const { language } = useContext(LanguageContext);

	return (
		<div
			className={`${mt} relative z-10 flex flex-col`}
			style={{
				backgroundColor:
					settings?.footer_background ||
					settings?.background ||
					"#e8e8e8",
			}}
		>
			<div className="container mx-auto p-10 pb-16 flex lg:flex-row lg:justify-around flex-col space-y-4 lg:space-y-0">
				<div className="max-w-lg drop-shadow-lg">
					<h4
						className={`text-4xl max-w-fit`}
						style={{
							color: settings?.primary_text
								? settings.primary_text
								: "#3d3d3d",
							fontFamily:
								settings?.text_title_font_name || "serif",
						}}
					>
						{response[language]?.footer_title_text ||
							"Triangle Studio"}
					</h4>
					<p
						className={`text-lg mt-3`}
						style={{
							color: settings?.primary_text
								? settings.primary_text
								: "#3d3d3d",
							fontFamily:
								settings?.text_body_font_name || "sans-serif",
						}}
					>
						{response[language]?.footer_description_text ||
							`Lorem ipsum dolor sit amet consectetur adipisicing elit.
						Laboriosam quisquam assumenda, voluptates pariatur
						debitis dolores. Dignissimos, veniam. Atque laboriosam
						fugit nobis mollitia, ullam voluptatibus obcaecati dicta
						molestias dolorem, culpa ex?`}
					</p>
				</div>
				<div className="drop-shadow-lg">
					<h4
						className={`text-4xl max-w-fit`}
						style={{
							color: settings?.primary_text
								? settings.primary_text
								: "#3d3d3d",
							fontFamily:
								settings?.text_title_font_name || "serif",
						}}
					>
						{response[language]?.contact_us_title_text ||
							"Contact US"}
					</h4>
					<div className="flex flex-col mt-3 text-lg ">
						{ settings?.contact_us_phone && 
						  <a
							className={`hover:underline cursor-pointer`}
							href={`tel:${
								settings?.contact_us_phone || "+436603708880"
							}`}
							style={{
								color: settings?.primary_text
									? settings.primary_text
									: "#3d3d3d",
								fontFamily:
									settings?.text_body_font_name ||
									"sans-serif",
							}}
						  >
							{settings?.contact_us_phone || "+43 660 3708880"}
						  </a> 
						}
						<a
							className={`hover:underline cursor-pointer`}
							href={`tel:${
								settings?.contact_us_phone2 || "+436603708880"
							}`}
							style={{
								color: settings?.primary_text
									? settings.primary_text
									: "#3d3d3d",
								fontFamily:
									settings?.text_body_font_name ||
									"sans-serif",
							}}
						>
							{settings?.contact_us_phone2 || "+43 660 3708880"}
						</a>
						<a className={`hover:underline cursor-pointer`}
						   href={`tel:${
								settings?.contact_us_phone3 || "+436603708880"
							}`}
							style={{
								color: settings?.primary_text
									? settings.primary_text
									: "#3d3d3d",
								fontFamily:
									settings?.text_body_font_name ||
									"sans-serif",
							}}
						>
							{settings?.contact_us_phone3 || "+43 660 3708880"}
						</a>
						<a
							className={`hover:underline cursor-pointer`}
							href={`mailto:${
								settings?.contact_us_email ||
								"office@trianglestudio.at"
							}`}
							style={{
								color: settings?.primary_text
									? settings.primary_text
									: "#3d3d3d",
								fontFamily:
									settings?.text_body_font_name ||
									"sans-serif",
							}}
						>
							{settings?.contact_us_email ||
								"office@trianglestudio.at"}
						</a>
						<div
							className={`drop-shadow-lg`}
							style={{
								color: settings?.primary_text
									? settings.primary_text
									: "#3d3d3d",
								fontFamily:
									settings?.text_body_font_name ||
									"sans-serif",
							}}
						>
							{response[language]?.contact_us_description_text ||
								`We support green companies – reach out and get your
							discount!`}
						</div>
						<div>
							{settings?.social_links &&
								settings.social_links.map((social, i) => (
									<SocialIcon
										{...social}
										style={{
											boxShadow: "none",
										}}
									/>
								))}
						</div>
					</div>
				</div>
				<div className="drop-shadow-lg">
					<h4
						className={`text-4xl max-w-fit`}
						style={{
							color: settings?.primary_text
								? settings.primary_text
								: "#3d3d3d",
							fontFamily:
								settings?.text_title_font_name || "serif",
						}}
					>
						{response[language]?.legal_title_text || "Legal"}
					</h4>
					<div className="flex flex-col mt-3 text-lg ">
						<Link to="/imprint/">
							<div
								className={`hover:underline cursor-pointer`}
								style={{
									color: settings?.primary_text
										? settings.primary_text
										: "#3d3d3d",
									fontFamily:
										settings?.text_body_font_name ||
										"sans-serif",
								}}
							>
								{response[language]?.imprint_text || "Imprint"}
							</div>
						</Link>
						<Link to="/privacy/">
							<div
								className={`hover:underline cursor-pointer`}
								style={{
									color: settings?.primary_text
										? settings.primary_text
										: "#3d3d3d",
									fontFamily:
										settings?.text_body_font_name ||
										"sans-serif",
								}}
							>
								{response[language]?.privacy_policy_text ||
									"Privacy Policy"}
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
